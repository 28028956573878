var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AddEmployeeInfo" },
    [
      _c("HeaderBack", {
        attrs: { title: "添加员工信息" },
        on: {
          back: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c("div", { staticClass: "AddEmployeeInfo-content" }, [
        _c(
          "div",
          { staticClass: "basics-msg" },
          [
            _c("BlockTitle", {
              staticClass: "msg-title",
              attrs: { title: "基本信息", "is-bold": true },
            }),
            _c(
              "div",
              { staticClass: "msg-content" },
              [
                _c(
                  "a-form-model",
                  {
                    ref: "addInfo",
                    attrs: {
                      model: _vm.staffAddInfo,
                      rules: _vm.rules,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                    },
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "姓名", prop: "staffName" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入姓名" },
                                  model: {
                                    value: _vm.staffAddInfo.staffName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.staffAddInfo,
                                        "staffName",
                                        $$v
                                      )
                                    },
                                    expression: "staffAddInfo.staffName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "手机号", prop: "phoneNum" } },
                              [
                                _c("a-input", {
                                  staticStyle: { width: "30%" },
                                  attrs: { placeholder: "请输入手机号" },
                                  model: {
                                    value: _vm.staffAddInfo.phoneNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.staffAddInfo,
                                        "phoneNum",
                                        $$v
                                      )
                                    },
                                    expression: "staffAddInfo.phoneNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "证件类型", prop: "idType" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: { select: _vm.idTypeSelect },
                                    model: {
                                      value: _vm.staffAddInfo.idType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.staffAddInfo,
                                          "idType",
                                          $$v
                                        )
                                      },
                                      expression: "staffAddInfo.idType",
                                    },
                                  },
                                  _vm._l(_vm.idTypeList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(_vm._s(item.content))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "证件号码", prop: "idNum" } },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入证件号" },
                                  model: {
                                    value: _vm.staffAddInfo.idNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.staffAddInfo, "idNum", $$v)
                                    },
                                    expression: "staffAddInfo.idNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "性别" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.staffAddInfo.sex,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.staffAddInfo, "sex", $$v)
                                      },
                                      expression: "staffAddInfo.sex",
                                    },
                                  },
                                  _vm._l(_vm.sexList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(_vm._s(item.content))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "出生日期" } },
                              [
                                _c("a-date-picker", {
                                  attrs: {
                                    "value-format": "YYYY-MM-DD",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.staffAddInfo.birthdate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.staffAddInfo,
                                        "birthdate",
                                        $$v
                                      )
                                    },
                                    expression: "staffAddInfo.birthdate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "籍贯", prop: "nativePlace" } },
                              [
                                _c("CbSelectArea", {
                                  ref: "selectArea",
                                  attrs: {
                                    "area-show": true,
                                    "container-width": "362px",
                                  },
                                  on: {
                                    getChangeValue: _vm.getChangeValue,
                                    change: _vm.getStrandValue,
                                  },
                                  model: {
                                    value: _vm.staffAddInfo.nativePlace,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.staffAddInfo,
                                        "nativePlace",
                                        $$v
                                      )
                                    },
                                    expression: "staffAddInfo.nativePlace",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "居住地址",
                                  name: "address",
                                  prop: "address",
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "1-50个字符" },
                                  model: {
                                    value: _vm.staffAddInfo.address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.staffAddInfo, "address", $$v)
                                    },
                                    expression: "staffAddInfo.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "onTheJob-msg" },
          [
            _c("BlockTitle", {
              staticClass: "msg-title",
              attrs: { title: "在职信息", "is-bold": true },
            }),
            _c(
              "div",
              { staticClass: "msg-content" },
              [
                _c(
                  "a-form-model",
                  {
                    ref: "jobInfo",
                    attrs: {
                      model: _vm.onJobAddInfo,
                      rules: _vm.jobRules,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                    },
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "部门" } },
                              [
                                _c("DepTreeSelect", {
                                  attrs: {
                                    "select-width": "362px",
                                    multiple: false,
                                    placeholder: "请选择部门",
                                  },
                                  on: { getValueSelect: _vm.orgChange },
                                  model: {
                                    value: _vm.onJobAddInfo.orgId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.onJobAddInfo, "orgId", $$v)
                                    },
                                    expression: "onJobAddInfo.orgId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "岗位" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: !_vm.onJobAddInfo.orgId,
                                      "allow-clear": "",
                                    },
                                    on: { change: _vm.jobChange },
                                    model: {
                                      value: _vm.onJobAddInfo.postId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "postId",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.postId",
                                    },
                                  },
                                  _vm._l(_vm.postList, function (item) {
                                    return _c(
                                      "a-select-opt-group",
                                      {
                                        key: item.postTypeId,
                                        attrs: { label: item.postTypeName },
                                      },
                                      _vm._l(item.postList, function (it) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: it.postId,
                                            attrs: { value: it.postId },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(it.postName) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "职务" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: !_vm.onJobAddInfo.orgId,
                                      "allow-clear": "",
                                    },
                                    model: {
                                      value: _vm.onJobAddInfo.duty,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.onJobAddInfo, "duty", $$v)
                                      },
                                      expression: "onJobAddInfo.duty",
                                    },
                                  },
                                  _vm._l(_vm.dutyList, function (it) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: it.dutyId,
                                        attrs: { value: it.dutyId },
                                      },
                                      [_vm._v(" " + _vm._s(it.dutyName) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "职级" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: !_vm.onJobAddInfo.postId,
                                      "allow-clear": "",
                                    },
                                    model: {
                                      value: _vm.onJobAddInfo.jobGradeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "jobGradeId",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.jobGradeId",
                                    },
                                  },
                                  _vm._l(_vm.rankList, function (item) {
                                    return _c(
                                      "a-select-opt-group",
                                      {
                                        key: item.jobTypeId,
                                        attrs: { label: item.jobTypeName },
                                      },
                                      _vm._l(item.jobGradeList, function (it) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: it.jobGradeId,
                                            attrs: { value: it.jobGradeId },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  it.jobTypeCode + it.jobGrade
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "合同公司" } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.onJobAddInfo.contractCo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "contractCo",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.contractCo",
                                    },
                                  },
                                  _vm._l(_vm.companyList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.companyId,
                                        attrs: {
                                          disabled: item.status !== 1,
                                          value: item.companyId,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.companyName))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: { label: "汇报上级", name: "checkPass" },
                              },
                              [
                                _c("PerTreeSelect", {
                                  attrs: { placeholder: "请选择汇报上级" },
                                  model: {
                                    value: _vm.onJobAddInfo.reportId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.onJobAddInfo,
                                        "reportId",
                                        $$v
                                      )
                                    },
                                    expression: "onJobAddInfo.reportId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "员工状态",
                                  prop: "staffStatus",
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    on: { change: _vm.Statuschange },
                                    model: {
                                      value: _vm.onJobAddInfo.staffStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "staffStatus",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.staffStatus",
                                    },
                                  },
                                  _vm._l(_vm.staffStatusList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(_vm._s(item.content))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "聘用形式",
                                  prop: "engageMode",
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.onJobAddInfo.engageMode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "engageMode",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.engageMode",
                                    },
                                  },
                                  _vm._l(_vm.engageModeList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.codeKey,
                                        attrs: { value: item.codeKey },
                                      },
                                      [_vm._v(_vm._s(item.content))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: { label: "入职日期", prop: "entryDate" },
                              },
                              [
                                _c("a-date-picker", {
                                  attrs: {
                                    "value-format": "YYYY-MM-DD",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.onJobAddInfo.entryDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.onJobAddInfo,
                                        "entryDate",
                                        $$v
                                      )
                                    },
                                    expression: "onJobAddInfo.entryDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: "工号", prop: "staffNum" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "staffnuminput" },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        size: "default",
                                        placeholder: "选择聘用形式后生成工号",
                                      },
                                      model: {
                                        value: _vm.onJobAddInfo.staffNum,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.onJobAddInfo,
                                            "staffNum",
                                            $$v
                                          )
                                        },
                                        expression: "onJobAddInfo.staffNum",
                                      },
                                    }),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          disabled: _vm.configDisable
                                            ? true
                                            : _vm.disabledGenerate
                                            ? true
                                            : false,
                                        },
                                        on: { click: _vm.generateJobid },
                                      },
                                      [_vm._v(" 生成工号 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "工作地点",
                                  prop: "workplaceId",
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.onJobAddInfo.workplaceId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onJobAddInfo,
                                          "workplaceId",
                                          $$v
                                        )
                                      },
                                      expression: "onJobAddInfo.workplaceId",
                                    },
                                  },
                                  _vm._l(_vm.addressList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.addressId,
                                        attrs: { value: item.addressId },
                                      },
                                      [_vm._v(_vm._s(item.addressName))]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "转正日期",
                                  prop: "formalDate",
                                },
                              },
                              [
                                _c("a-date-picker", {
                                  attrs: {
                                    "value-format": "YYYY-MM-DD",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.onJobAddInfo.formalDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.onJobAddInfo,
                                        "formalDate",
                                        $$v
                                      )
                                    },
                                    expression: "onJobAddInfo.formalDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-row",
        {
          staticClass: "AddEmployeeInfo-bottom",
          attrs: { type: "flex", justify: "center" },
        },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.addEmployee } },
            [_vm._v("保存")]
          ),
          _c(
            "a-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }