var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ScannInvitation" },
    [
      _c("HeaderBack", {
        attrs: { title: "邀请员工开通" },
        on: {
          back: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c("div", { staticClass: "ScannInvitation-content" }, [
        _c(
          "div",
          { staticClass: "steps" },
          _vm._l(_vm.stepsList, function (step, index) {
            return _c("div", { key: index, staticClass: "step" }, [
              _c("div", { staticClass: "step-items" }, [
                _c("span", { staticClass: "num" }, [_vm._v(_vm._s(index + 1))]),
                _c("span", { staticClass: "text" }, [_vm._v(_vm._s(step))]),
              ]),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "invitation-container" }, [
          _c(
            "div",
            { staticClass: "invitation-box" },
            [
              _c("div", { staticClass: "invitation_box_left" }, [
                _c("img", {
                  staticClass: "invitation-box_img",
                  attrs: { src: _vm.url.img, alt: "" },
                }),
                _c("div", [_vm._v("分享二维码，邀请员工快速加入企业")]),
              ]),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.download } },
                [_vm._v("保存二维码")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }