var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ExcelImport" },
    [
      _c("BatchImport", {
        ref: "batchimport",
        attrs: {
          title: "批量导入员工",
          "import-template": "importUser",
          "model-id": "1534340833881665537",
          "show-update-list": true,
          multiple: false,
          "file-list": _vm.fileList,
          "file-size": 20,
          "data-list": _vm.dataList,
          "show-three": false,
        },
        on: { goBack: _vm.goBack, getStaffStatistics: _vm.getStaffStatistics },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }