var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "roster" },
    [
      !_vm.componentName
        ? [
            _c("div", { staticClass: "tab_box" }, [
              _c("div", { staticClass: "tab_box_left" }, [
                _c("div", { staticClass: "completed" }, [
                  _c("span", { staticClass: "completedText onJobNum" }, [
                    _vm._v(_vm._s(_vm.tabList.onJobCount)),
                  ]),
                  _c("span", [_vm._v("在职")]),
                ]),
              ]),
              _c("div", { staticClass: "tab_box_center" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab_box_center_count",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          path: "/staffIndex/induction",
                        })
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "tab_box_color_num" }, [
                      _vm._v(_vm._s(_vm.tabList.awaitEntryCount)),
                    ]),
                    _c("span", [_vm._v("待入职")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab_box_center_count",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: "/staffIndex/quit" })
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "tab_box_color_num" }, [
                      _vm._v(_vm._s(_vm.tabList.awaitLeaveCount)),
                    ]),
                    _c("span", [_vm._v("待离职")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab_box_center_count",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: "/staffIndex/worker" })
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "tab_box_color_num" }, [
                      _vm._v(_vm._s(_vm.tabList.tryOutCount)),
                    ]),
                    _c("span", [_vm._v("试用期")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab_box_center_count",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({
                          path: "/staffIndex/transfer",
                        })
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "tab_box_color_num" }, [
                      _vm._v(_vm._s(_vm.tabList.transferCount)),
                    ]),
                    _c("span", [_vm._v("调动中")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "tab_box_right" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab_box_right_count",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: "/staffIndex/archive" })
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "completed" }, [
                      _c("span", { staticClass: "completedText" }, [
                        _vm._v(_vm._s(_vm.tabList.awaitPerfectCount)),
                      ]),
                      _c("span", [_vm._v("待完善")]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c("RosterTable", {
                  ref: "RosterTable",
                  attrs: { "job-type-info": _vm.rosterTableInfo },
                  on: { change: _vm.change },
                }),
              ],
              1
            ),
          ]
        : _c(_vm.componentName, {
            tag: "component",
            on: {
              back: function ($event) {
                _vm.functionType = ""
              },
              getStaffStatistics: _vm.getStaffStatistics,
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }